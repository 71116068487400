@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "rabar-1";
  src: url(../src/fonts/Rabar_031.ttf);
}
@font-face {
  font-family: "rabar-2";
  src: url(../src/fonts/Rabar_031.ttf);
}
@font-face {
  font-family: "rabar-3";
  src: url(../src/fonts/Rabar_033.ttf);
}
@font-face {
  font-family: "rabar-4";
  src: url(../src/fonts/Rabar_033.ttf);
}
@font-face {
  font-family: "rabar-5";
  src: url(../src/fonts/Rabar_033.ttf);
}
