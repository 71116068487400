@font-face {
  font-family: "rabar-3";
  src: url(../fonts/Rabar_033.ttf);
}

.paginate {
  font-family: rabar-3;
  margin-top: 30px;
  height: 40px;
  color: #abcd6c;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.paginate a {
  font-family: rabar-3;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid #abcd6c;
}
.disabled {
  color: gray;
}
.activelink {
  background-color: #abcd6c;
  color: white;
}

@media only screen and (max-width: 639px) {
  .paginate {
    flex-wrap: wrap;
    row-gap: 25px;
    column-gap: 10px;
  }
}
